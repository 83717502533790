<i18n>
{
  "en": {
    "hideOutliers": "Hide outliers"
  },
  "it": {
    "hideOutliers": "Nascondi outliers"
  }
}
</i18n>

<template>
  <div class="mod">
    <div id="fdd-plot" class="peak-wrapper noselect">
      <!-- Header -->
      <div class="main-wrap over-info">
        <div style="display: flex;">
          <h2>{{ $t("graphTitlesStats.clustering") }}</h2>
          <div>
            <!-- <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" /> -->
            <!-- <FDDBalloon :visible="isInfoVisible" style="position: absolute; margin-left: -520px" @close="isInfoVisible = false" /> -->
          </div>
        </div>
        <p class="first-block">{{ $t("graphSubTitlesStats.clustering") }}</p>
      </div>
      <div id="graph-wrapper">
        <!-- Wrapper Info in Overlay -->
        <div class="infoi" v-show="this.loadingCount > 0 || !this.freqsFromApi || !this.freqsFromApi.Modalfreqs">
          <h2 v-if="(!this.freqsFromApi || !this.freqsFromApi.Modalfreqs) && this.loadingCount === 0">No FDD Data...</h2>
          <h2 v-else>{{ $t("message.loading") }}...</h2>
        </div>
        <!-- Grafico Cluster -->
        <div class="mod-plot">
          <ModalPlot :height="530" :traces="this.modalTraces" xTitle="Frequency (Hz)" yTitle="Frequency (Hz)" @selected="selectFreq" />
          <div id="outliers-switch">
            <p>{{ $t("hideOutliers") }}:</p>
            <label class="switch"
              ><input type="checkbox" v-model="hideOutliers" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <!-- Gradico Dens. Prob. -->
        <div class="dens-plot">
          <p>
            Selected modal freq. avg:
            {{
              this.freqsFromApi && this.freqsFromApi.Modalfreqs && this.freqsFromApi.Modalfreqs[selectedClusterIndex] != undefined
                ? this.freqsFromApi.Modalfreqs[selectedClusterIndex].avg.toFixed(4)
                : "-"
            }}
            Hz
          </p>
          <DensProb class="graph" :values="this.probTraces" xTitle="Freq [Hz]" :yTitle="$t('message.freqApp')" v-if="true" />
        </div>
        <!-- Tabella Frequenze -->
        <div class="info-tab">
          <table style="width: 100%">
            <tr>
              <th></th>
              <th>{{ $t("message.minValue") }}</th>
              <th>{{ $t("message.meanValue") }}</th>
              <th>{{ $t("message.maxValue") }}</th>
              <th>{{ $t("message.stdDeviation") }}</th>
              <th>ΔF(%)</th>
            </tr>
            <tr
              :class="[index === selectedClusterIndex ? 'selected' : '', 'tab-row']"
              v-for="(cluster, index) in this.freqsFromApi ? this.freqsFromApi.Modalfreqs : []"
              :key="index"
              @click="selectFreq(index)"
            >
              <td><span class="table-dot" :style="{ backgroundColor: index < colorsMap.length ? colorsMap[index] : '' }"></span></td>
              <td>{{ cluster.min.toFixed(4) }}</td>
              <td>{{ cluster.avg.toFixed(4) }}</td>
              <td>{{ cluster.max.toFixed(4) }}</td>
              <td>{{ cluster.std_deviation.toFixed(4) }}</td>
              <td>{{ cluster.deltaF.toFixed(4) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalPlot from "../../graphs/ModalPlot.vue";
import DensProb from "../../graphs/DensityProbHistogram.vue";
import api from "../../../services/api";

export default {
  name: "ModalFreqs",
  props: {
    sensorsType: String,
  },
  components: {
    ModalPlot,
    DensProb,
  },
  data() {
    return {
      loadingCount: 0,
      fetchingQueue: [],
      showCalendar: false,
      selectedClusterIndex: null,
      freqsFromApi: null,
      colorsMap: [],
      hideOutliers: true,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedGroupID: "FDD/getSelectedGroupID",
      getSelectedStructure: "structure/getSelectedStructure",
      getDateRange: "FDD/getFreqClusterDateRange",
      getSelectedAxis: "FDD/getSelectedAxis",
    }),
    // Computed Traces freq. modali
    modalTraces: function() {
      let traces = [];
      if (this.freqsFromApi && this.freqsFromApi.Modalfreqs) {
        const sortedFreqs = this.freqsFromApi.Modalfreqs;
        sortedFreqs.sort(function(a, b) {
          return a.avg - b.avg;
        });
        sortedFreqs.map((mf, index) => {
          traces.push({
            x: mf.freqs.map((dt) => dt.toFixed(6)),
            y: mf.freqs.map((dt) => dt.toFixed(6)),
            name: "Cluster " + index,
            marker: { color: this.colorsMap.length === sortedFreqs.length ? this.colorsMap[index] : null },
          });
        });
        if (this.freqsFromApi.Outliersfreqs && !this.hideOutliers) {
          traces.push({
            x: this.freqsFromApi.Outliersfreqs,
            y: this.freqsFromApi.Outliersfreqs,
            name: "Outliers",
            marker: { color: "gray" },
          });
        }
      }
      return traces;
    },
    // Computed Traces dens. prob.
    probTraces: function() {
      if (
        this.selectedClusterIndex != null &&
        this.selectedClusterIndex != undefined &&
        this.freqsFromApi.Modalfreqs != undefined &&
        this.freqsFromApi.Modalfreqs[this.selectedClusterIndex] != undefined
      ) {
        return this.freqsFromApi.Modalfreqs[this.selectedClusterIndex].freqs;
      } else {
        return [];
      }
    },
  },
  methods: {
    selectFreq(clusterIndex) {
      // Controllo che non sia selezionato un outlier
      if (this.freqsFromApi && this.freqsFromApi.Modalfreqs && clusterIndex < this.freqsFromApi.Modalfreqs.length) {
        this.selectedClusterIndex = clusterIndex;
      }
    },
    // API Fetch
    async fetchCluster() {
      if (
        this.getDateRange[0] &&
        this.getDateRange[1] &&
        this.getSelectedStructure &&
        this.getSelectedGroupID &&
        ((this.sensorsType === "accelerometer" && this.getSelectedAxis) || this.sensorsType === "deck")
      ) {
        const myLoadingID = this.loadingCount + 1;
        this.loadingCount = myLoadingID;
        //this.colorsMap = [];
        this.fetchingQueue.push({ id: myLoadingID, timestamp: new Date().getTime() });
        this.fetchingQueue.sort((b, a) => a.timestamp - b.timestamp);
        //console.log("Queue before", this.fetchingQueue);
        const resp = await api.getFreqCluster(
          new Date(this.getDateRange[0]),
          new Date(this.getDateRange[1]),
          this.getSelectedStructure._id,
          this.sensorsType,
          this.getSelectedGroupID,
          this.sensorsType === "accelerometer" ? this.getSelectedAxis : null
        );
        // Se l'ultima chiamata effettuata sono io
        if (this.fetchingQueue[0] && this.fetchingQueue[0].id === myLoadingID) {
          if (Object.keys(resp).length > 0) {
            this.freqsFromApi = JSON.parse(JSON.stringify(resp));
            if (this.freqsFromApi && this.freqsFromApi.Modalfreqs) {
              this.selectedClusterIndex = this.freqsFromApi.Modalfreqs.length > 0 ? 0 : null;
              this.colorsMap = this.freqsFromApi.Modalfreqs.map(() => "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6));
            }
          } else {
            // Risposta vuota -> svuoto
            this.freqsFromApi = {};
          }
          // Se la risposta è quella che aspettavo -> resetto queue
          this.fetchingQueue = [];
          this.loadingCount = 0;
        }
        //this.fetchingQueue = this.fetchingQueue.filter((ld) => ld.id != myLoadingID);
        //this.loadingCount -= 1;
      }
    },
  },
  watch: {
    getSelectedStructure: {
      handler: async function(newValue, oldValue) {
        console.log("A");
        if (oldValue) {
          console.log("[FDD Clust] New Structure");
          this.fetchCluster();
        }
      },
    },
    getSelectedGroupID: {
      handler: async function(newValue, oldValue) {
        if (oldValue) {
          console.log("[FDD Clust] New SelectedNumber");
          this.fetchCluster();
        }
      },
    },
    sensorsType: {
      handler: async function(newValue, oldValue) {
        if (oldValue) {
          console.log("[FDD Clust] New SensorsType");
          this.fetchCluster();
        }
      },
    },
    getSelectedAxis: {
      handler: async function(newValue, oldValue) {
        if (oldValue) {
          console.log("[FDD Clust] New Axis");
          this.fetchCluster();
        }
      },
    },
    getDateRange: {
      handler: async function(oldValue) {
        if (oldValue) {
          console.log("[FDD Clust] New Date");
          this.fetchCluster();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
/* p {
  font-size: 0.9em;
} */
h2 {
  font-size: 0.9em;
}
h1 {
  color: white;
  grid-column: 1/3;
  font-size: 0.9em;
  margin-top: 30px;
}
/* Main Wrapper */
.mod {
  min-width: 1000px;
  width: 92%;
  display: grid;
  margin-left: 4%;
  margin-right: 4%;
}
/* ID Evento principale */
#fdd-plot {
  width: 100%;
  min-height: 700px;
}
#graph-wrapper {
  width: 100%;
  min-height: 700px;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: 6.5fr 3.5fr;
  position: relative;
}

/* Wrapper Evento */
.peak-wrapper {
  position: relative;
  margin-top: 30px;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

.mod-plot {
  grid-row: 2;
  grid-column: 1;
  box-sizing: content-box;
  border-right: solid 1px gray;
}
.dens-plot {
  grid-row: 2;
  grid-column: 2;
  box-sizing: content-box;
}
.dens-plot > p {
  text-align: left;
  margin-left: 20px;
}
.info-tab {
  grid-row: 3;
  grid-column: 1/3;
  padding: 40px;
  margin: 0 30px;
  color: white;
}
table {
  border-collapse: collapse;
  border-radius: 20px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 2px rgb(80, 84, 105); /* this draws the table border  */
}

td {
  border: solid 2px rgb(80, 84, 105);
  border-collapse: collapse;
}
table,
th,
td {
  border-left: none;
  border-right: none;
  overflow: hidden;
  font-size: 0.9em;
}

.table-dot {
  height: 15px;
  width: 15px;
  background-color: unset;
  border-radius: 50%;
  /* display: inline-block; */
  display: block;
  text-align: center;
  margin: 0 auto;
}
/* Info in alto sinistra sul tipo di grafico */
.over-info > h2 {
  /* font-size: 1em; */
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  margin-top: 0;
  margin-bottom: 0;
}
.over-info {
  grid-row: 1;
  grid-column: 1/3;
  height: fit-content;
  text-align: left;
  position: relative;
  margin-top: 2%;
  padding-bottom: 10px;
  display: grid;
  padding-left: 60px;
  padding-right: 60px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.6);
  grid-template-columns: 100%;
}

.select-container {
  color: white;
  display: flex;
}

.info-group {
  cursor: pointer;
  display: flex;
  align-items: baseline;
}

.info-group > p {
  margin-right: 5px;
}

.tab-row {
  cursor: pointer;
}
.tab-row:hover {
  background-color: rgba(150, 153, 161, 0.2);
  z-index: -1;
}
.selected {
  background-color: rgb(31, 34, 46);
}

/* Freccia */
i {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  position: relative;
  /*   margin-bottom: auto;
  margin-top: auto; */
  margin-left: 10px;
  bottom: 2px;
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.1, 0.96);
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#calendar {
  position: absolute;
  top: 83px;
  max-width: 770px;
  margin-left: 5%;
  background-color: rgb(45, 48, 65);
}
/* Radio positioning */
.radio-container {
  margin: 10px;
}
#axis-select {
  margin-left: 15%;
}

.main-wrap {
  margin-top: 30px;
  display: grid;
  padding-left: 60px;
  grid-template-columns: max-content;
}
.over-info > h2 {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  margin-top: 0;
  margin-bottom: 0;
}
.over-info {
  text-align: left;
  position: relative;
  border-bottom: solid 1px rgba(255, 255, 255, 0.6);
}
.pointer {
  margin-left: 6px;
}
#outliers-switch {
  display: flex;
  margin-left: 70px;
}
#outliers-switch > p {
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
}
</style>
