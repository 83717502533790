<template>
  <div class="over noselect">
    <!-- Blocco dei controlli -->
    <div id="amppeaks">
      <!-- Wrapper Info in Overlay -->
      <div class="infoi" v-show="!this.loaded">
        <h2>{{ $t("message.loading") }}...</h2>
      </div>
      <div class="main-wrap over-info">
        <div style="display: flex;">
          <h2>{{ $t("graphTitlesStats.anomaly") }}</h2>
          <div>
            <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" />
            <AnomProbBalloon :visible="isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
          </div>
        </div>
        <p class="first-block">{{ $t("graphSubTitlesStats.anomaly") }}</p>
        <div id="date-select" @click="showCalendar = !showCalendar">
          <p class="info-btn">{{ $t("message.dateRange") }}:</p>
          <h2 class="info-btn">{{ this.formattedDate[0] + " - " + this.formattedDate[1] }}</h2>
          <i class="info-btn" v-bind:class="[showCalendar ? 'up' : 'down']"></i>
        </div>
      </div>
      <ModalCalendar
        v-if="showCalendar && getDateRange[0] && getDateRange[1]"
        :datarange="[getDateRange[0], getDateRange[1]]"
        @close="showCalendar = false"
        @update="updateDate"
        @range-selected="rangeSelected"
        class="modal-calendar"
        id="calendar"
      />
      <!-- Blocco dell'anomaly plot -->
      <anomaly
        :xTitle="this.sensor1 ? this.sensor1.eui.substr(this.sensor1.eui.length - 5) + ' [mm]' : '-'"
        :yTitle="this.sensor2 ? this.sensor2.eui.substr(this.sensor2.eui.length - 5) + ' [mm]' : '-'"
        :tracesObject="this.getTraces"
        @events-selected="this.showModal"
      />
    </div>
    <!-- Blocco del componente modale con i grafici degli eventi -->
    <ModalWrapper v-show="isModalVisible" @close="closeModal" v-if="loaded && vibrationsResponse.length > 0">
      <template v-slot:body>
        <div class="plot-container">
          <LinePlot :title="vibrationsResponse[0].eui" :traces="[{ y: vibrationsResponse[0].payload.dati }]" xTitle="Time[samples]" yTitle="Pk-Pk[mm]" />
          <LinePlot :title="vibrationsResponse[1].eui" :traces="[{ y: vibrationsResponse[1].payload.dati }]" xTitle="Time[samples]" yTitle="Pk-Pk[mm]" />
        </div>
      </template>
    </ModalWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalCalendar from "../../ui/ModalCalendar";
import Anomaly from "../../graphs/Anomaly.vue";
import ModalWrapper from "../../ui/ModalWrapper.vue";
import LinePlot from "../../graphs/LinePlot.vue";
import AnomProbBalloon from "../../tutorial/balloons/stats/AnomProbBl.vue";
import { getEntireDateRange } from "../../../helpers/dates";
import axios from "axios";
import config from "../../../config";

export default {
  name: "anomaly-plot",
  components: {
    Anomaly,
    ModalCalendar,
    ModalWrapper,
    LinePlot,
    AnomProbBalloon,
  },
  data() {
    return {
      isInfoVisible: false,
      showCalendar: false,
      loaded: false,
      isModalVisible: false,
      selSensor1: "",
      selSensor2: "",
      event1: null,
      event2: null,
      vibrationsResponse: [],
      actualDateRange: [],
    };
  },
  computed: {
    ...mapGetters("anomaly", ["getDateRange", "getTraces"]),
    ...mapGetters({
      sensor1: "densProb1/getSelectedSensor",
      sensor2: "densProb2/getSelectedSensor",
    }),
    formattedDate() {
      let firstDate = this.formatDate(this.getDateRange[0]);
      let lastDate = this.formatDate(this.getDateRange[1]);
      return [firstDate, lastDate];
    },
  },

  methods: {
    ...mapActions("anomaly", ["setDateRange", "getAnomalyTraces"]),
    formatDate(date) {
      if (date) {
        let day = date.getDate();
        let year = date.getFullYear();
        let month = date.toLocaleString("default", { month: "short" });
        return day + " " + month + " " + year;
      } else {
        return null;
      }
    },
    updateDate() {
      this.setDateRange(this.actualDateRange);
      this.showCalendar = false;
    },
    rangeSelected(dateRange) {
      this.actualDateRange = dateRange;
    },
    async showModal(event1, event2) {
      this.event1 = event1;
      this.event2 = event2;
      await this.fetchSelectedEvents(event1, event2);
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async fetchSelectedEvents(ID1, ID2) {
      this.loaded = false;
      const response = await axios.post(config.API_ENDPOINT + "sensor_data/vibrations", {
        ids: [ID1, ID2],
      });
      this.vibrationsResponse[0] = response.data[0];
      this.vibrationsResponse[1] = response.data[1];
      this.loaded = true;
    },
  },
  async mounted() {
    this.setDateRange(getEntireDateRange(new Date().setDate(new Date().getDate() - 7), new Date()));
  },
  watch: {
    getDateRange: {
      handler: async function() {
        this.loaded = false;
        await this.getAnomalyTraces();
        this.loaded = true;
      },
      deep: true,
    },
    sensor1: {
      handler: async function(value) {
        console.log("V2", value);
        if (value && value.eui) {
          this.selSensor1 = value.eui;
          this.loaded = false;
          await this.getAnomalyTraces();
          this.loaded = true;
        }
      },
      deep: true,
    },
    sensor2: {
      handler: async function(value) {
        console.log("V2", value);
        if (value && value.eui) {
          this.selSensor1 = value.eui;
          this.loaded = false;
          await this.getAnomalyTraces();
          this.loaded = true;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
#calendar {
  position: absolute;
  top: 133px;
  max-width: 770px;
  margin-left: 260px;
  background-color: rgb(45, 48, 65);
}

#date-select {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
}
#date-select p {
  margin-right: 10px;
}
/* p {
  font-size: 0.9em;
} */
h2 {
  font-size: 0.9em;

  color: rgb(255, 255, 255);
}
h1 {
  color: white;
  font-size: 1em;
  text-align: center;
}
.over {
  min-width: 800px;
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
  height: fit-content;
}
#amppeaks {
  display: grid;
  position: relative;
  margin-top: 30px;
  padding-bottom: 1%;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  overflow: hidden;
}
.plot-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 8px;
  justify-content: space-around;
}
.over-info {
  border-bottom: solid 1px rgba(255, 255, 255, 0.6);
}

/* Freccia */
i {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  position: relative;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 10px;
  bottom: 2px;
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.1, 0.96);
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.main-wrap {
  margin-top: 30px;
  display: grid;
  padding-left: 60px;
  grid-template-columns: max-content;
}
.over-info > h2 {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  margin-top: 0;
  margin-bottom: 0;
}
.over-info {
  text-align: left;
  position: relative;
}
.pointer{
  margin-left: 6px;
}
</style>
