<i18n>
{
  "en": {
      "title": "Anomaly Displacement Detector",
      "subTitle": "Graph that highlights the displacement events defined as anomalous compared to those defined as standard, recorded in the selected time interval. It is possible to analyze the movements of a single sensor or to correlate those of two different sensors:",
      "li1": "The blue points correspond to the peaks identified as “Normal” or “Standard”.",
      "li2": "The orange points correspond to the peaks identified as “Abnormal”."

  },
  "it": {
      "title": "Rivelatore della anomalie di spostamento",
      "subTitle": "Grafico che evidenzia gli eventi di spostamento definiti anomali rispetto a quelli definiti come standard, registrati nell’intervallo temporale selezionato. È possibile analizzare gli spostamenti di un singolo sensore oppure mettere in correlazione quelli di due sensori differenti:",
      "li1": "I punti blu corrispondono ai picchi identificati come “Normali” o “Standard”.",
      "li2": "I punti arancioni corrispondono ai picchi identificati come “Anomali”."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
      <ul>
        <li>{{ $t("li1") }}</li>
        <li>{{ $t("li2") }}</li>
      </ul>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "anomProbBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 440px;
}
li {
  margin: 6px 0;
}
</style>