<template>
  <transition name="modal-info-fade">
    <div class="wrapper" v-if="values" v-click-outside="onClickOutside">
      <div class="item" v-for="(item, index) in values" :key="index" @click="selected(item)">
        <p :class="[selectedSensor.eui === item.eui ? 'selected-text' : '', 'noselect']">
          {{ sensorTypes.toUpperCase() }} {{ item.eui.substr(item.eui.length - 5) }}
        </p>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "ModalSensorSelect",
  props: {
    values: Array,
    selectedSensor: Object,
    sensorTypes: String,
  },
  methods: {
    selected(value) {
      this.$emit("selected", value);
    },
    onClickOutside() {
      this.$emit("selected", this.selectedSensor);
    },
  },
};
</script>
<style scoped>
.background {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: red;
  z-index: 1;
}
p {
  margin: 0;
}
.wrapper {
  color: white;
  text-align: left;
  width: fit-content;
  height: fit-content;
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 10px;
  border: solid 2px rgba(255, 255, 255, 0.4);
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  z-index: 12;
}

.item {
  font-weight: 700;
  cursor: pointer;
  background-color: rgb(91, 96, 118);
  padding: 3px 10px;
  border-radius: 5px;
  margin: 10px;
  min-width: 40px;
  display: flex;
}

.selected-text {
  color: rgb(21, 146, 230);
}

.modal-info-fade-enter,
.modal-info-fade-leave-active {
  opacity: 0;
}

.modal-info-fade-enter-active,
.modal-info-fade-leave-active {
  transition: opacity 0.3s ease;
}
</style>