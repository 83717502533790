<template>
  <div class="fdd">
    <div id="fdd-plot" class="peak-wrapper noselect">
      <div class="main-wrap over-info">
        <div style="display: flex;">
          <h2>{{ $t("graphTitlesStats.FDD") }}</h2>
          <div>
            <img
              class="pointer"
              src="../../../../public/assets/icons/help.svg"
              alt="drag"
              @mouseover="isInfoVisible = true"
              @mouseleave="isInfoVisible = false"
            />
            <FDDBalloon
              :visible="isInfoVisible"
              style="position: absolute; margin-left: 20px"
              @close="isInfoVisible = false"
            />
          </div>
        </div>
        <p class="first-block">{{ $t("graphSubTitlesStats.FDD") }}</p>
        <img
          @click="downloadFDD"
          class="download-btn"
          src="../../../../public/assets/download.svg"
        />
      </div>
      <div class="graph-and-info">
        <!-- Wrapper Info in Overlay -->
        <div class="infoi" v-show="!(this.getFDDOfSelectedGroup.length > 0)">
          <h2 v-if="!(this.getFDDOfSelectedGroup.length > 0)">
            No FDD Data...
          </h2>
          <h2 v-else>{{ $t("message.loading") }}...</h2>
        </div>
        <LinePlot
          :height="600"
          :traces="this.getTraces"
          xTitle="Date"
          yTitle="Frequency (Hz)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LinePlot from "../../graphs/LinePlot.vue";
import FDDBalloon from "../../tutorial/balloons/stats/FddBl.vue";
import { exportCSVFile } from "../../../helpers/download.js";

export default {
  name: "FreqCluster",
  props: {
    sensorsType: String
  },
  components: {
    LinePlot,
    FDDBalloon
  },
  data() {
    return {
      isInfoVisible: false,
      baseTrace: {
        connectgaps: true,
        line: {
          width: 4
        },
        marker: {
          size: 8
        },
        name: "Peaks Line",
        type: "scatter",
        mode: "lines+markers",
        xaxis: "x",
        yaxis: "y",
        opacity: 0.9,
        textfont: {}
      }
    };
  },
  computed: {
    ...mapGetters({
      getFDD: "FDD/getFDD",
      getSelectedGroupID: "FDD/getSelectedGroupID",
      getSelectedStructure: "structure/getSelectedStructure",
      getGroups: "structure/getGroups",
      getDateRange: "FDD/getFreqClusterDateRange",
      getSelectedAxis: "FDD/getSelectedAxis"
    }),
    getSelectedGroupObj() {
      return this.getGroups.find(gr => gr._id === this.getSelectedGroupID);
    },
    getFDDBySensorType() {
      const accTypes = [
        "accelerometerShortSampling",
        "accelerometerLongSampling",
        "accelerometerComplete"
      ];
      const deckTypes = ["deckShort1600"];
      let FDD = [];
      if (this.sensorsType === "accelerometer") {
        FDD = this.getFDD.filter(fdd => accTypes.includes(fdd.type));
      } else if (this.sensorsType === "deck") {
        FDD = this.getFDD.filter(
          fdd => !fdd.type || deckTypes.includes(fdd.type)
        );
      }
      return FDD;
    },
    getFDDOfSelectedGroup() {
      let fdd = [];
      if (this.sensorsType === "accelerometer" && this.getSelectedAxis) {
        fdd = this.getFDDBySensorType.filter(
          fdd =>
            fdd.group === this.getSelectedGroupID &&
            fdd.axis === this.getSelectedAxis
        );
      } else if (this.sensorsType === "deck") {
        fdd = this.getFDDBySensorType.filter(
          fdd => fdd.group === this.getSelectedGroupID
        );
      }
      return fdd;
    },
    getTraces() {
      const traces = this.getFDDOfSelectedGroup.map(i => i.traces);
      const labels = [].concat(...traces).map(i => i.label);
      const uniqueLabels = [...new Set(labels)];
      const basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      const tracesToPlot = uniqueLabels.map(ulab => {
        const filteredFDD = this.getFDDOfSelectedGroup.filter(fdd =>
          fdd.traces.find(tr => tr.label === ulab && tr.label !== -1)
        );
        let datesList = filteredFDD.map(function(fdd) {
          const date = new Date(fdd.date);
          date.setHours(0);
          return date;
        });
        const valuesList = filteredFDD.map(
          fdd =>
            fdd.traces.find(tr => tr.label === ulab && tr.label !== -1)
              .freq_value
        );
        return {
          ...basicTrace,
          name: ulab,
          line: {
            ...basicTrace.line,
            dash: this.checkIfValidFreq(valuesList) ? "solid" : "dashdot"
          },
          x: datesList,
          y: valuesList
        };
      });
      return tracesToPlot;
    }
  },
  methods: {
    ...mapActions({
      fetchFDD: "FDD/fetchFDD"
    }),
    checkIfValidFreq(values) {
      const validPercentage = 80; // Percentuale di punti sotto 1Hz per ritenersi valida
      const under1Hz = values.filter(v => v < 1).length;
      const total = values.length;
      return (under1Hz / total) * 100 < validPercentage;
    },
    /* Scarico .CSV */
    downloadFDD() {
      // Prendo le FDD relative al gruppo selezionati
      if (this.getFDDOfSelectedGroup.length > 0) {
        const flattenTraces = this.getFDDOfSelectedGroup
          .map(f => f.traces.map(tr => tr.label).filter(l => l !== -1))
          .flat();
        const uniqueLabels = Array.from(new Set(flattenTraces)).sort();
        console.log(`[FDD Download] Expected labels: ${uniqueLabels}`);
        let headers = {
          date: "Data (Locale)",
          groupID: "Group ID",
          groupName: "Group Name"
        };
        // File title
        const fileTitle = "FDD";
        // Columns craftin
        for (const label of uniqueLabels) {
          headers["freq" + label] = "Freq" + label + " [Hz]";
        }
        const events = this.getFDDOfSelectedGroup.map(fdd => {
          let singleEvent = {
            date: new Date(fdd.date).toLocaleDateString().replace(/,/g, ""),
            groupID: fdd.group,
            groupName: (this.getSelectedGroupObj || {}).name
          };
          // Datas crafting
          for (const label of uniqueLabels) {
            const currentFreq = (fdd.traces.find(tr => tr.label === label) || {}).freq_value;
            singleEvent["freq" + label] = currentFreq || "";
          }
          return singleEvent;
        });
        exportCSVFile(headers, events, fileTitle);
      }
    }
  },
  watch: {
    getSelectedStructure: {
      handler: async function() {
        await this.fetchFDD();
      },
      deep: true
    },
    getDateRange: {
      handler: async function() {
        await this.fetchFDD();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
/* p {
  font-size: 0.9em;
} */
h2 {
  font-size: 0.9em;
}
h1 {
  color: white;
  grid-column: 1/3;
  font-size: 0.9em;
  margin-top: 30px;
}
/* Main Wrapper */
.fdd {
  min-width: 1000px;
  width: 92%;
  column-gap: 2%;
  display: grid;
  grid-template-columns: 49% 49%;
  margin-left: 4%;
  margin-right: 4%;
}

/* ID Evento principale */
#fdd-plot {
  width: 100%;
  min-height: 700px;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
}

/* Wrapper Evento */
.peak-wrapper {
  position: relative;
  margin-top: 30px;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

/* Info in alto sinistra sul tipo di grafico */
/* .over-info > h2 {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  font-size: 0.8em;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info {
  text-align: left;
  position: relative;
  margin-top: 16px;
  display: grid;
  padding-left: 60px;
  padding-right: 60px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.6);
  grid-template-columns: 100%;
} */

.select-container {
  color: white;
  display: flex;
  /* margin-left: calc(15% - 30px); */
}

.info-group {
  cursor: pointer;
  display: flex;
  align-items: baseline;
}

.info-group > p {
  margin-right: 5px;
}

.download-btn {
  cursor: pointer;
  grid-row: 1/3;
  height: 18px;
  justify-self: end;
  margin-right: 32px;
  background-color: rgb(33, 150, 243);
  padding: 10px;
  grid-column: 2;
  border-radius: 10px;
}

/* Freccia */
i {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  position: relative;
  margin-left: 10px;
  bottom: 2px;
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.1, 0.96);
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#calendar {
  position: absolute;
  top: 123px;
  max-width: 770px;
  margin-left: 5%;
  background-color: rgb(45, 48, 65);
}

.graph-and-info {
  position: relative;
}

/* Radio positioning */
.radio-container {
  margin: 10px;
}
#axis-select {
  margin-left: 15%;
}

.main-wrap {
  margin-top: 30px;
  display: grid;
  padding-left: 60px;
  grid-template-columns: max-content;
}
.over-info > h2 {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  margin-top: 0;
  margin-bottom: 10px;
}
.over-info {
  text-align: left;
  position: relative;
  border-bottom: solid 1px rgba(255, 255, 255, 0.6);
}
.pointer {
  margin-left: 6px;
}
</style>
