<template>
  <div class="anomaly-plot-container">
    <div :ref="this.uuid" class="anomaly-plot"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist-min';

export default {
  name: "modal-plot",
  props: {
    title: String, //Stringa con it titolo del grafico
    traces: Array, //Contiente le traces
    xTitle: String, //Titolo asse x
    yTitle: String, //Titolo asse y
    height: Number,
  },
  data() {
    return {
      uuid: Math.random().toString(36).substr(2, 15),
    };
  },
  computed: {
    computedTraces: function () {
      return this.traces.map((trace, index) => ({
        x: trace.x,
        y: trace.y,
        name: trace.name,
        id: index,
        /*           idsx: this.tracesObject.standard ? this.tracesObject.standard.xOkIDS : [],
          idsy: this.tracesObject.standard ? this.tracesObject.standard.yOkIDS : [], */
        mode: "markers",
        type: "scatter",
        textposition: "top center",
        textfont: {
          family: "Raleway, sans-serif",
        },
        marker: { ...trace.marker, size: 12 },
      }));
      /* return [
        // Scatter eventi standard
        {
          x: this.tracesObject.standard ? this.tracesObject.standard.x : [],
          y: this.tracesObject.standard ? this.tracesObject.standard.y : [],
          idsx: this.tracesObject.standard ? this.tracesObject.standard.xOkIDS : [],
          idsy: this.tracesObject.standard ? this.tracesObject.standard.yOkIDS : [],
          mode: "markers",
          type: "scatter",
          name: "Standard",
          textposition: "top center",
          textfont: {
            family: "Raleway, sans-serif"
          },
          marker: { size: 12 }
        },
        // Scatter eventi anomali
        {
          x: this.tracesObject.anomaly ? this.tracesObject.anomaly.x : [],
          y: this.tracesObject.anomaly ? this.tracesObject.anomaly.y : [],
          idsx: this.tracesObject.anomaly ? this.tracesObject.anomaly.xAnomIDS : [],
          idsy: this.tracesObject.anomaly ? this.tracesObject.anomaly.yAnomIDS : [],
          mode: "markers",
          type: "scatter",
          name: "Anomalia",
          //text: ["B-a", "B-b", "B-c", "B-d", "B-e"],
          textfont: {
            family: "Times New Roman"
          },
          textposition: "bottom center",
          marker: { size: 12 }
        }
      ]; */
    },
    chart: function () {
      return {
        layout: {
          margin: {
            l: 120,
            r: 35,
            b: 90,
            t: 40,
            pad: 4,
          },
          xaxis: {
            showgrid: true,
            title: {
              text: this.xTitle,
            },
            gridcolor: "rgba(150,153,162,0.6)",
            gridwidth: 1,
            linecolor: "rgba(150,153,162,0.6)",
            linewidth: 2,
          },
          yaxis: {
            showgrid: true,
            title: {
              text: this.yTitle,
            },
            gridcolor: "rgba(150,153,162,0.4)",
            gridwidth: 1,
          },
          height: this.height || 650,
          showlegend: false,
          legend: {
            y: 1,
            x: 0.1,
            bordercolor: "rgba(255,255,255,0.6)",
            borderwidth: 1,
            yref: "paper",
            font: {
              family: "Arial, sans-serif",
              size: 20,
              color: "grey",
            },
          },
          paper_bgcolor: "rgba(45,48,65,1)",
          plot_bgcolor: "rgba(45,48,65,1)",
          font: {
            family: "Poppins",
            size: 14,
            color: "rgb(255,255,255)",
          },
          hovermode: "closest",
        },
      };
    },
  },
  methods: {
    extractIDS(data) {
      this.$emit("selected", data.points[0].data.id);
    },
  },
  mounted() {
    Plotly.newPlot(this.$refs[this.uuid], this.computedTraces, this.chart.layout, {
      responsive: true,
    }).then((gd) => {
      gd.on("plotly_click", this.extractIDS);
    });
  },
  watch: {
    computedTraces: {
      handler: function () {
        Plotly.react(this.$refs[this.uuid], this.computedTraces, this.chart.layout);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
p {
  color: white;
}
</style>
