<i18n>
{
  "en": {
      "title": "Modal Frequency Statistics",
      "subTitle": "Graph showing the trend of the modal frequencies of the structure, calculated daily through pre-set groups of DECK sensors and Accelerometers.\nBy correlating all the daily oscillations it is possible to distinguish extremely similar modes (modal frequencies less than 0.2 Hz distant) and to monitor the trend of the modal parameters during even a very long time interval.",
      "li1": "TSolid lines: Natural modal frequencies typical of the structure, linked only to environmental effects. These values are purged from the white noise of forcing such as traffic or anomalous loads.",
      "li2": "Dashed lines: Frequencies that can be associated with the contributions of the forcings, which can be associated with the passage of heavy loads or with traffic."

  },
  "it": {
      "title": "Statistiche delle Frequenze Modali",
      "subTitle": "Grafico che riporta l’andamento delle frequenze modali della struttura, calcolate giornalmente tramite gruppi pre-impostati di sensori DECK e Accelerometri.\nCorrelando tutte le oscillazioni giornaliere è possibile distinguere modi estremamente simili tra loro (frequenze modali distanti meno di 0.2 Hz) e monitorare l’andamento dei parametri modali durante un intervallo temporale anche molto lungo.",
      "li1": "Linee continue: Frequenze modali naturali proprie della struttura legate solo agli effetti ambientali. Tali valori sono epurati dal rumore bianco delle forzanti come traffico o carichi anomali. ",
      "li2": "Linee tratteggiate: Frequenze associabili ai contributi delle forzanti, associabili al passaggio di carichi pesanti o al traffico.."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
      <ul>
        <li>{{ $t("li1") }}</li>
        <li>{{ $t("li2") }}</li>
      </ul>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "FDDBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 520px;
}
li {
  margin: 6px 0;
}
</style>