<template>
  <div class="dens-prob-hist">
    <div :ref="this.uuid" class="hist-plot"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist-min';
import stats from "../../stats";

export default {
  name: "prob-density-plot",
  props: {
    title: String, //Titolo del grafico
    values: Array, //Array contente i valori da plottare
    binSize: Number, //Valore di bin size
    xTitle: String,
    yTitle: String,
  },
  data() {
    return {
      uuid: Math.random().toString(36).substr(2, 15),
      firstExec: true,
      loading: false,
      config: {
        responsive: true,
        toImageButtonOptions: {
          format: "png", // one of png, svg, jpeg, webp
          filename: "DeckPlot",
          width: 1100,
          scale: 1, // Multiply title/legend/axis/canvas sizes by this factor,
          title: "PIPPO",
        },
      },
    };
  },
  computed: {
    traces: function () {
      return [
        {
          x: this.values,
          name: "Peaks Probabilty",
          type: "histogram",
          histnorm: "probability density",
          marker: {
            color: "#2391FE",
          },
          line: {} /*,
					outputxrange: [0, 1]*/,
          xbins: {
            size: this.binSize,
          },
        },
        {
          uid: "47f169",
          line: {
            color: "#CC3333",
            width: 4,
          },
          name: "Gaussian Fit",
          type: "scatter",
          x: this.fromPeaksToGaussX(this.values),
          y: this.fromPeaksToGaussY(this.values),
          xaxis: "x",
          yaxis: "y",
          marker: {
            line: {},
          },
          error_x: {},
          error_y: {},
          error_z: {},
          opacity: 0.9,
          textfont: {},
        },
      ];
    },
    chart: function () {
      return {
        layout: {
          showlegend: false,
          height: this.height || 450,
          margin: {
            l: 102,
            r: 35,
            b: 60,
            t: 80,
            pad: 2,
          },
          title: {
            text: this.title || "",
            font: {
              family: "Poppins",
              size: 22,
            },
          },
          autosize: true,
          xaxis: {
            tickangle: 0,
            tickfont: { family: "Poppins", size: 12 },
            title: {
              text: this.xTitle,
              font: {
                family: "Poppins",
                size: 16,
              },
            },
            gridcolor: "rgba(150,153,162,0.6)",
            gridwidth: 1,
            linecolor: "rgba(150,153,162,0.6)",
            linewidth: 2,
            range: this.xRange || null,
          },
          yaxis: {
            title: {
              text: this.yTitle,
              font: {
                family: "Poppins",
                size: 16,
              },
            },
            gridcolor: "rgba(150,153,162,0.4)",
            gridwidth: 1,
            range: this.yRange || null,
          },
          bargap: 0.2,
          //boxgap: 0.3
          paper_bgcolor: "rgba(45,48,65,1)",
          plot_bgcolor: "rgba(45,48,65,1)",
          font: {
            family: "Poppins",
            size: 14,
            color: "rgb(255,255,255)",
          },
          shapes: this.threshold
            ? [
                {
                  type: "line",
                  xref: "paper",
                  x0: 0,
                  y0: this.threshold,
                  x1: 1,
                  y1: this.threshold,
                  line: {
                    color: "rgb(255, 0, 0)",
                    width: 3,
                    dash: "dashdot",
                  },
                },
              ]
            : [],
        },
      };
    },
    /*     getKurtosis() {
      let arr = Object.keys(this.values).map(k => this.values[k]);
      return stats.kurtosis(arr);
    },
    getPeaksLen() {
      return this.values.length;
    },
    getMeanValue() {
      return stats.CalculateMeanValue(this.values);
    },
    getVariance() {
      return stats.CalculateVariance(this.values);
    } */
  },
  methods: {
    fromPeaksToGaussX: function (values) {
      return stats.SortArray(values);
    },
    fromPeaksToGaussY: function (values) {
      let gaussY = stats.CalculateGaussArray(values);
      return gaussY;
    },
  },
  mounted() {
    Plotly.newPlot(this.$refs[this.uuid], this.traces, this.chart.layout, this.config);
  },
  watch: {
    traces: {
      handler: function () {
        if (this.$refs[this.uuid]) {
          Plotly.react(this.$refs[this.uuid], this.traces, this.chart.layout, this.config);
        }
      },
      deep: true,
    },
    chart: {
      handler: function () {
        if (this.$refs[this.uuid]) {
          Plotly.newPlot(this.$refs[this.uuid], this.traces, this.chart.layout, this.config);
        }
      },
      deep: true,
    },
    height: {
      handler: function () {
        if (this.$refs[this.uuid]) {
          Plotly.newPlot(this.$refs[this.uuid], this.traces, this.chart.layout, this.config);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
p {
  color: white;
}
</style>
