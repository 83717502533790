<template>
  <div class="over">
    <div id="probs" class="module-wrapper noselect">
      <!-- Wrapper Info in Overlay -->
      <div class="infoi" v-show="!this.getSelectedSensor1 || !this.getSelectedSensor2 || !this.loaded1 || !this.loaded2">
        <h2>{{ $t("message.loading") }}...</h2>
      </div>
      <!-- Overay per i modal -->
      <div class="infoi" v-show="showCalendar1 || showCalendar2"></div>
      <div class="main-wrap over-info">
        <div style="display: flex;">
          <h2>{{ $t("graphTitlesStats.densProb") }}</h2>
          <!-- <h2 v-else-if="sensorsType === 'accelerometer'">{{ $t("title.densProbAcc") }}</h2> -->
          <div>
            <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" />
            <DensProbBalloon :visible="isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
          </div>
        </div>
        <p class="first-block">{{ $t("graphSubTitlesStats.densProb") }}</p>
      </div>

      <div id="controls" v-if="this.getSelectedSensor1 && this.getSelectedSensor2 && this.getDateRange1[0] && this.getDateRange2[0]">
        <div class="control-block">
          <div class="range-select">
            <!-- Modal Info Selezione Deck -->
            <ModalCalendar
              v-if="showCalendar1 && getDateRange1[0] && getDateRange1[1]"
              :datarange="[getDateRange1[0], getDateRange1[1]]"
              @close="showCalendar1 = false"
              @update="updateDate1"
              @range-selected="rangeSelected1"
              class="modal-calendar"
              id="calendar1"
            />
            <!-- FIRST LINE -->
            <div class="vert-divider second-block"></div>
            <div @click="showCalendar1 = !showCalendar1">
              <p class="date-range-text">{{ $t("message.dateRange") }}:</p>
              <div class="date-range-select">
                <h2>{{ this.formattedDate1[0] + " - " + this.formattedDate1[1] }}</h2>
                <i v-bind:class="[showCalendar1 ? 'up' : 'down']"></i>
              </div>
            </div>
          </div>
          <div class="bin-size">
            <label class="binsize-label" for="formControlRange">BinSize</label>
            <input type="range" step="0.002" class="form-control-range" id="formControlRange1" min="0.001" max="0.08" v-model="binSize1" />
          </div>
        </div>
        <div class="noselect" id="lock" @click="lock = !lock">
          <img :src="lock ? require('../../../../public/assets/icons/locked.svg') : require('../../../../public/assets/icons/unlocked.svg')" alt="Lock Icon" />
        </div>
        <div class="control-block">
          <div class="range-select" :class="{ inactive: lock }">
            <!-- Modal Info Selezione Deck -->
            <ModalCalendar
              v-if="showCalendar2 && getDateRange2[0] && getDateRange2[1]"
              :datarange="[getDateRange2[0], getDateRange2[1]]"
              @close="showCalendar2 = false"
              @update="updateDate2"
              @range-selected="rangeSelected2"
              class="modal-calendar"
              id="calendar2"
            />
            <!-- FIRST LINE -->
            <div class="vert-divider second-block"></div>
            <div @click="showCalendar2 = !showCalendar2 && !lock">
              <p class="date-range-text">{{ $t("message.dateRange") }}:</p>
              <div class="date-range-select">
                <h2>{{ this.formattedDate2[0] + " - " + this.formattedDate2[1] }}</h2>
                <i v-bind:class="[showCalendar2 ? 'up' : 'down']"></i>
              </div>
            </div>
          </div>
          <div class="bin-size">
            <label class="binsize-label" for="formControlRange">BinSize</label>
            <input type="range" step="0.002" class="form-control-range" id="formControlRange1" min="0.001" max="0.08" v-model="binSize2" />
          </div>
        </div>
      </div>
      <DensityProbHistogram class="graph" :values="this.getProb1" :binSize="parseFloat(this.binSize1)" xTitle="Pk-Pk [mm]" :yTitle="$t('message.freqApp')" />
      <DensityProbHistogram class="graph" :values="this.getProb2" :binSize="parseFloat(this.binSize2)" xTitle="Pk-Pk [mm]" :yTitle="$t('message.freqApp')" />
      <div class="add-info">
        <div class="inner-info">
          <p>
            <span class="value">{{ getMeanValue1 || "-" }}</span>
          </p>
          <p>{{ $t("message.meanValue") }}</p>
        </div>
        <div class="inner-info">
          <p>
            <span class="value">{{ getVariance1 || "-" }}</span>
          </p>
          <p>{{ $t("message.variance") }}</p>
        </div>
        <div class="inner-info">
          <p>
            <span class="value">{{ getPeaksLen1 || 0 }}</span>
          </p>
          <p>{{ $t("message.counterEvents") }}</p>
        </div>
        <div class="inner-info">
          <p>
            <span class="value">{{ getKurtosis1 || "-" }}</span>
          </p>
          <p>Kurtosis</p>
        </div>
      </div>
      <div class="add-info">
        <div class="inner-info">
          <p>
            <span class="value">{{ getMeanValue2 || "-" }}</span>
          </p>
          <p>{{ $t("message.meanValue") }}</p>
        </div>
        <div class="inner-info">
          <p>
            <span class="value">{{ getVariance2 || "-" }}</span>
          </p>
          <p>{{ $t("message.variance") }}</p>
        </div>
        <div class="inner-info">
          <p>
            <span class="value">{{ getPeaksLen2 || 0 }}</span>
          </p>
          <p>{{ $t("message.counterEvents") }}</p>
        </div>
        <div class="inner-info">
          <p>
            <span class="value">{{ getKurtosis2 || "-" }}</span>
          </p>
          <p>Kurtosis</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ModalCalendar from "../../ui/ModalCalendar.vue";
import DensityProbHistogram from "../../graphs/DensityProbHistogram.vue";
import DensProbBalloon from "../../tutorial/balloons/stats/DensProbBl.vue";
import stats from "../../../stats";
import { getEntireDateRange } from "../../../helpers/dates";

export default {
  name: "densProbability",
  components: {
    ModalCalendar,
    DensityProbHistogram,
    DensProbBalloon,
  },
  props: {
    sensorsType: String,
  },
  data() {
    return {
      isInfoVisible: false,
      lock: false,
      showCalendar1: false,
      showCalendar2: false,
      actualDateRange1: [],
      actualDateRange2: [],
      binSize1: 0.05,
      binSize2: 0.05,
    };
  },
  computed: {
    ...mapGetters({
      getDateRange1: "densProb1/getDateRange",
      getDateRange2: "densProb2/getDateRange",
      getSelectedSensor1: "densProb1/getSelectedSensor",
      getSelectedSensor2: "densProb2/getSelectedSensor",
      getSensors: "structure/getSensors",
      getProb1: "densProb1/getDiffPeaks",
      getProb2: "densProb2/getDiffPeaks",
      loaded1: "densProb1/getLoadingStatus",
      loaded2: "densProb2/getLoadingStatus",
    }),
    /* Date */
    formattedDate1() {
      let firstDate = this.formatDate(this.getDateRange1[0]);
      let lastDate = this.formatDate(this.getDateRange1[1]);
      return [firstDate, lastDate];
    },
    formattedDate2() {
      let firstDate = this.formatDate(this.getDateRange2[0]);
      let lastDate = this.formatDate(this.getDateRange2[1]);
      return [firstDate, lastDate];
    },
    getKurtosis1() {
      let arr = Object.keys(this.getProb1).map((k) => this.getProb1[k]);
      return this.getProb1.length > 0 && stats.kurtosis(arr).toFixed(6);
    },
    getPeaksLen1() {
      return this.getProb1.length > 0 && this.getProb1.length;
    },
    getMeanValue1() {
      return this.getProb1.length > 0 && stats.CalculateMeanValue(this.getProb1).toFixed(6);
    },
    getVariance1() {
      return this.getProb1.length > 0 && stats.CalculateVariance(this.getProb1).toFixed(6);
    },
    /* Statistiche */
    getKurtosis2() {
      let arr = Object.keys(this.getProb2).map((k) => this.getProb2[k]);
      return this.getProb2.length > 0 && stats.kurtosis(arr).toFixed(6);
    },
    getPeaksLen2() {
      return this.getProb2.length > 0 && this.getProb2.length;
    },
    getMeanValue2() {
      return this.getProb2.length > 0 && stats.CalculateMeanValue(this.getProb2).toFixed(6);
    },
    getVariance2() {
      return this.getProb2.length > 0 && stats.CalculateVariance(this.getProb2).toFixed(6);
    },
  },
  methods: {
    ...mapActions({
      setDateRange1: "densProb1/setDateRange",
      setDateRange2: "densProb2/setDateRange",
      fetchProb1: "densProb1/fetchDiffPeaks",
      fetchProb2: "densProb2/fetchDiffPeaks",
    }),
    updateDate1() {
      this.setDateRange1(this.actualDateRange1);
      this.showCalendar1 = false;
      if (this.lock) {
        this.setDateRange2(this.actualDateRange1);
      }
    },
    rangeSelected1(dateRange) {
      this.actualDateRange1 = dateRange;
    },
    updateDate2() {
      this.setDateRange2(this.actualDateRange2);
      this.showCalendar2 = false;
    },
    rangeSelected2(dateRange) {
      this.actualDateRange2 = dateRange;
    },
    formatDate(date) {
      if (date) {
        let day = date.getDate();
        let year = date.getFullYear();
        let month = date.toLocaleString("default", { month: "short" });
        return day + " " + month + " " + year;
      } else {
        return null;
      }
    },
  },
  async mounted() {
    await this.setDateRange1(getEntireDateRange(new Date().setDate(new Date().getDate() - 7), new Date()));
    this.actualDateRange1 = this.getDateRange1;
    await this.setDateRange2(getEntireDateRange(new Date().setDate(new Date().getDate() - 7), new Date()));
    this.actualDateRange2 = this.getDateRange2;
  },
  watch: {
    lock: {
      // Aggiorno il secondo data-range se ho attivato il lock
      handler: async function(newValue) {
        if (newValue === true) {
          this.setDateRange2(this.actualDateRange1);
        }
      },
    },
    getSelectedSensor1: {
      handler: async function(newValue, oldValue) {
        if (newValue && oldValue) await this.fetchProb1();
      },
      deep: true,
    },
    getSelectedSensor2: {
      handler: async function(newValue, oldValue) {
        if (newValue && oldValue) await this.fetchProb2();
      },
      deep: true,
    },
    getDateRange1: {
      handler: async function(newValue, oldValue) {
        if (newValue && oldValue) await this.fetchProb1();
      },
      deep: true,
    },
    getDateRange2: {
      handler: async function(newValue, oldValue) {
        if (newValue && oldValue) await this.fetchProb2();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
/* p {
  font-size: 0.9em;
} */
h2 {
  font-size: 0.9em;
}
h1 {
  color: white;
  grid-column: 1/3;
  /* font-size: 1em; */
  margin-top: 30px;
}
/* Main Wrapper */
.over {
  min-width: 1000px;
  width: 92%;
  column-gap: 2%;
  display: grid;
  grid-template-columns: 49% 49%;
  margin-left: 4%;
  margin-right: 4%;
  height: fit-content;
}

/* Wrapper Evento */
.module-wrapper {
  position: relative;
  margin-top: 30px;
  padding-bottom: 1%;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

#probs {
  width: 100%;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: 5fr 5fr;
  column-gap: 60px;
}

#controls {
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 5fr 60px 5fr;
  border-bottom: solid 1px rgba(255, 255, 255, 0.6);
  height: fit-content;
}
.control-block {
  display: grid;
  grid-template-columns: 5fr 5fr;
  padding: 10px 10px 10px 15%;
  margin-top: 22px;
}
.inactive p,
.inactive h2,
.inactive i {
  color: grey !important;
  border: none !important;
}
#lock {
  align-self: end;
  width: 100%;
  height: 50px;
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}
#lock img {
  max-height: 100%;
  width: 70%;
}
.control-block:first-child {
  grid-column: 1;
}
.control-block:last-child {
  grid-column: 3;
}

.date-range-text {
  margin: 10px 0 5px 0;
  text-align: left;
}
.date-range-select {
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
}

.device-select {
  cursor: pointer;
  grid-column: 1/3;
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
}
.device-select h2 {
  margin-left: 5px;
}

.add-info {
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-template-rows: 5fr 5fr;
  padding: 20px 40px;
}
.value {
  font-size: 1.2em;
  font-weight: 600;
  color: white;
}
.inner-info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: left;
  padding: 8px 20px;
}
.inner-info p {
  margin: 0;
  margin-top: 5px;
  color: rgb(198, 198, 198);
}
.inner-info:nth-child(1) {
  border: solid 2px rgba(80, 84, 105, 0.8);
  border-top-left-radius: 20px;
}
.inner-info:nth-child(2) {
  border-top: solid 2px rgba(80, 84, 105, 0.8);
  border-bottom: solid 2px rgba(80, 84, 105, 0.8);
  border-right: solid 2px rgba(80, 84, 105, 0.8);
  border-top-right-radius: 20px;
}
.inner-info:nth-child(3) {
  border-bottom: solid 2px rgba(80, 84, 105, 0.8);
  border-left: solid 2px rgba(80, 84, 105, 0.8);
  border-right: solid 2px rgba(80, 84, 105, 0.8);
  border-bottom-left-radius: 20px;
}
.inner-info:nth-child(4) {
  border-bottom: solid 2px rgba(80, 84, 105, 0.8);
  border-right: solid 2px rgba(80, 84, 105, 0.8);
  border-bottom-right-radius: 20px;
}
.bin-size {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bin-size input {
  margin-top: 10px;
  margin-bottom: 7px;
}

#calendar1 {
  position: absolute;
  top: 143px;
  max-width: 770px;
  margin-left: 5%;
  background-color: rgb(45, 48, 65);
}

#calendar2 {
  position: absolute;
  top: 143px;
  right: 5%;
  max-width: 770px;
  background-color: rgb(45, 48, 65);
}

/* Freccia */
i {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  position: relative;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 10px;
  bottom: 2px;
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.1, 0.96);
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.main-wrap {
  margin-top: 30px;
  display: grid;
  margin-left: 60px;
  grid-template-columns: max-content;
}
.over-info > h2 {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  margin-top: 0;
  margin-bottom: 0;
}
.over-info {
  grid-column: 1/3;
  text-align: left;
  position: relative;
}
.pointer{
  margin-left: 6px;
}
</style>
