<template>
  <div class="over">
    <div class="heading">
      <h1>{{ $t("title.modalFreq") }}</h1>
    </div>
    <div class="content">
      <div class="device-select noselect" @click="showModal = !showModal">
        <p>{{ $t("message.groupSelection") }}:</p>
        <h2>
          {{ (getSelectedGroupObj || {}).name }} -
          {{ getEUISByGroupID(getSelectedGroupID) }}
        </h2>
        <i v-bind:class="[showModal ? 'up' : 'down']"></i>
      </div>
      <ModalGroupSelect
        v-if="showModal"
        :sensorTypes="sensorsType"
        :groups="getFilteredGroupsBySensorType"
        :selectedGroupID="getSelectedGroupID"
        @selected="selectGroupID"
        id="device1"
      />
      <ModalCalendar
        v-if="showCalendar && getDateRange[0] && getDateRange[1]"
        :datarange="[getDateRange[0], getDateRange[1]]"
        @close="showCalendar = false"
        @update="updateDate"
        @range-selected="rangeSelected"
        class="modal-calendar"
        id="calendar"
      />
      <div @click="showCalendar = !showCalendar" class="info-group">
        <p class="info-btn">{{ $t("message.dateRange") }}:</p>
        <h2 class="info-btn">
          {{ this.formattedDate[0] + " - " + this.formattedDate[1] }}
        </h2>
        <i class="info-btn" v-bind:class="[showCalendar ? 'up' : 'down']"></i>
      </div>
      <!-- Selzione asse -->
      <div
        v-if="sensorsType === 'accelerometer'"
        id="axis-select"
        class="info-group"
      >
        <p class="info-btn">{{ $t("message.axis") }}:</p>
        <div class="radio-container">
          <label class="radio-label">
            <input type="radio" value="X" v-model="actualAxis" />
            <span class="radio-checkmark"></span>
          </label>
          <label class="value">X</label>
        </div>
        <div class="radio-container">
          <label class="radio-label">
            <input type="radio" value="Y" v-model="actualAxis" />
            <span class="radio-checkmark"></span>
          </label>
          <label class="value">Y</label>
        </div>
        <div class="radio-container">
          <label class="radio-label">
            <input type="radio" value="Z" v-model="actualAxis" />
            <span class="radio-checkmark"></span>
          </label>
          <label class="value">Z</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ModalGroupSelect from "../../ui/ModalGroupSelect.vue";
import ModalCalendar from "../../ui/ModalCalendar.vue";
import { getEntireDateRange } from "../../../helpers/dates";

export default {
  name: "groupSelect",
  components: {
    ModalGroupSelect,
    ModalCalendar
  },
  data() {
    return {
      showModal: false,
      actualAxis: null,
      tempDateRange: [],
      actualDateRange: [],
      showCalendar: false
    };
  },
  props: {
    sensorsType: String
  },
  computed: {
    ...mapGetters({
      getSelectedGroupID: "FDD/getSelectedGroupID",
      getGroups: "structure/getGroups",
      getSensors: "structure/getSensors",
      getDateRange: "FDD/getFreqClusterDateRange",
      getSelectedAxis: "FDD/getSelectedAxis"
    }),
    getSelectedGroupObj() {
      return this.getGroups.find(gr => gr._id === this.getSelectedGroupID);
    },
    formattedDate: function() {
      let firstDate = this.formatDate(this.getDateRange[0]);
      let lastDate = this.formatDate(this.getDateRange[1]);
      return [firstDate, lastDate];
    },
    getFilteredGroupsBySensorType() {
      if (this.sensorsType) {
        const euisOfSelectedType = this.getSensors
          .filter(sn => sn.type === this.sensorsType)
          .map(dev => dev.eui);
        return this.getGroups.filter(gr =>
          gr.devices.every(device => euisOfSelectedType.includes(device.eui))
        );
      } else {
        return [];
      }
    }
  },
  methods: {
    ...mapActions({
      setSelectedGroupID: "FDD/setSelectedGroupID",
      setDateRange: "FDD/setFreqClusterDateRange",
      setSelectedAxis: "FDD/setSelectedAxis"
    }),
    updateDate() {
      this.setDateRange(this.actualDateRange);
      this.showCalendar = false;
    },
    rangeSelected(dateRange) {
      this.actualDateRange = dateRange;
    },
    formatDate(date) {
      if (date) {
        let day = date.getDate();
        let year = date.getFullYear();
        let month = date.toLocaleString("default", { month: "short" });
        return day + " " + month + " " + year;
      } else {
        return null;
      }
    },
    selectGroupID(groupID) {
      this.setSelectedGroupID(groupID);
      this.showModal = false;
    },
    getEUISByGroupID(groupID) {
      const group = this.getGroups.find(gr => gr._id === groupID);
      return group ? group.devices.map(dev => dev.eui.substr(dev.eui.length - 5)) : null;
    }
  },
  async mounted() {
    const initalGroups = this.getFilteredGroupsBySensorType;
    if (initalGroups[0]) {
      await this.selectGroupID(initalGroups[0]._id);
    } else {
      await this.selectGroupID(null);
    }
    await this.setDateRange(
      getEntireDateRange(
        new Date().setDate(new Date().getDate() - 30),
        new Date()
      )
    );
    this.actualDateRange = this.getDateRange;
    this.actualAxis = "X";
  },
  watch: {
    // Quanto cambia il tipo di sensore aggiorno il gruppo selezionato
    getFilteredGroupsBySensorType: {
      handler: async function() {
        const initalGroups = this.getFilteredGroupsBySensorType;
        if (initalGroups[0]) {
          await this.selectGroupID(initalGroups[0]._id);
        } else {
          await this.selectGroupID(null);
        }
      },
      deep: true
    },
    actualAxis: {
      handler: function(newAxis) {
        this.setSelectedAxis(newAxis);
      }
    },
    getSelectedAxis: {
      handler: function(newAxis) {
        this.actualAxis = newAxis;
      }
    }
  }
};
</script>
<style scoped>
/* Main Wrapper */
.over {
  min-width: 1000px;
  width: 92%;
  display: grid;
  grid-template-columns: 10fr;
  grid-template-rows: auto;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 20px;
  height: fit-content;
  border: solid 2px white;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  padding: 0 5%;
  box-sizing: border-box;
}

/* Wrapper Evento */
.heading {
  text-align: center;
  display: flex;
  justify-content: center;
  color: white;
  width: 100%;
  height: fit-content;
  grid-row: 1;
  grid-column: 1/3;
  position: relative;
  border-bottom: solid 2px rgb(150, 153, 162);
}
h1 {
  font-size: 0.9em;
  padding: 8px 0;
}
/* h2 {
  font-size: 0.9em;
} */
.content {
  margin: 10px 0;
  width: 100%;
  height: fit-content;
  grid-row: 2;
  position: relative;
  display: grid;
  grid-template-columns: minmax(100px, 50%) 10fr max-content;
  justify-content: baseline;
  align-items: center;
}
.device-select {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.device-select h2 {
  margin-left: 5px;
}
/* Freccia */
i {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  position: relative;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 10px;
  bottom: 2px;
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.1, 0.96);
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#device1 {
  position: absolute;
  top: 50px;
  left: 280px;
}
.info-group {
  cursor: pointer;
  display: flex;
  align-items: baseline;
  justify-self: center;
}
/* Radio positioning */
.radio-container {
  margin: 10px;
}
#axis-select {
  justify-self: end;
  width: fit-content;
}
.info-group > p {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
}
.value {
  color: white;
}
#calendar {
  position: absolute;
  top: 20px;
  left: 190px;
  max-width: 770px;
  /* margin-left: 5%; */
  background-color: rgb(45, 48, 65);
}
</style>
