var cov = require('compute-covariance');


function CalculateMeanValue(arr) {
  var acc = 0;
  arr.forEach(element => {
    acc = element + acc;
  });
  return acc / arr.length;
}

/*
function CalculateRMS(arr) {
  var sum_of_squares = arr.reduce(function(s, x) {
    return s + x * x;
  }, 0);
  return Math.sqrt(sum_of_squares / arr.length);
}
*/

//Check whether is a number or not
function isNum(args) {
  args = args.toString();
  if (args.length == 0) return false;
  for (var i = 0; i < args.length; i++) {
    if (
      (args.substring(i, i + 1) < "0" || args.substring(i, i + 1) > "9") &&
      args.substring(i, i + 1) != "." &&
      args.substring(i, i + 1) != "-"
    ) {
      return false;
    }
  }
  return true;
}

// Calculate the variance
function CalculateVariance(arr) {
  var len = 0;
  var sum = 0;
  for (let i = 0; i < arr.length; i++) {
    if (!isNum(arr[i])) {
      alert(arr[i] + " is not number, Variance Calculation failed!");
      return 0;
    } else {
      len = len + 1;
      sum = sum + parseFloat(arr[i]);
    }
  }
  var v = 0;
  if (len > 1) {
    var mean = sum / len;
    for (let i = 0; i < arr.length; i++) {
      v = v + (arr[i] - mean) * (arr[i] - mean);
    }
    return v / len;
  } else {
    return 0;
  }
}

// Return array for Gaussian Plot
function CalculateGaussArray(values) {
  //let rms = CalculateRMS(values);
  let meanValue = CalculateMeanValue(values);
  let variance = CalculateVariance(values);
  //let varianceSqrt = Math.pow(variance, 2);
  let arrGauss = [];
  let sortedValues = SortArray(values);

  for (let i = 0; i < sortedValues.length; i++) {
    arrGauss[i] =
      (1 / Math.sqrt(2 * Math.PI * variance)) *
      Math.pow(
        Math.E,
        -Math.pow(sortedValues[i] - meanValue, 2) / (2 * variance)
      );
  }
  return arrGauss;
}

function SortArray(values) {
  let sortedValues = [...values];
  sortedValues.sort(function (a, b) {
    return a - b;
  });
  return sortedValues;
}

// Kurtosis
function kurtosis(arr) {
  if (!Array.isArray(arr)) {
    throw new TypeError(
      "kurtosis()::invalid input argument. Must provide an array."
    );
  }
  var len = arr.length,
    delta = 0,
    delta_n = 0,
    delta_n2 = 0,
    term1 = 0,
    N = 0,
    mean = 0,
    M2 = 0,
    M3 = 0,
    M4 = 0,
    g;

  for (var i = 0; i < len; i++) {
    N += 1;

    delta = arr[i] - mean;
    delta_n = delta / N;
    delta_n2 = delta_n * delta_n;

    term1 = delta * delta_n * (N - 1);

    M4 +=
      term1 * delta_n2 * (N * N - 3 * N + 3) +
      6 * delta_n2 * M2 -
      4 * delta_n * M3;
    M3 += term1 * delta_n * (N - 2) - 3 * delta_n * M2;
    M2 += term1;
    mean += delta_n;
  }
  // Calculate the population excess kurtosis:
  g = (N * M4) / (M2 * M2) - 3;
  // Return the corrected sample excess kurtosis:
  return ((N - 1) / ((N - 2) * (N - 3))) * ((N + 1) * g + 6);
} // end FUNCTION kurtosis()


// Ellipse draw

// Linspace
function makeArr(startValue, stopValue, cardinality) {
  var arr = [];
  var step = (stopValue - startValue) / (cardinality - 1);
  for (var i = 0; i < cardinality; i++) {
    arr.push(startValue + (step * i));
  }
  return arr;
}



function ellipseZ(vectorOne, vectorTwo) {
  const stdX = Math.sqrt(CalculateVariance(vectorOne));
  const stdY = Math.sqrt(CalculateVariance(vectorTwo));

  const muX = CalculateMeanValue(vectorOne);
  const muY = CalculateMeanValue(vectorTwo);
  const mat = cov(vectorOne, vectorTwo);
  const rho = (mat[0][1]) / (stdX * stdY)

  const size = 100;
  const x = makeArr(0, 2, size);
  const y = makeArr(0, 2, size);

  var z = new Array(size);

  for (var k = 0; k < size; k++) {
    z[k] = new Array(size);
  }

  for (var i = 0; i < size; i++) {
    for (var j = 0; j < size; j++) {
      z[i][j] = (1 / (2 * Math.PI * stdX * stdY * Math.sqrt(1 - Math.pow(rho, 2)))) * Math.exp(-(1 / (2 * (1 - Math.pow(rho, 2)))) * (((Math.pow((x[i] - muX), 2)) / Math.pow(stdX, 2)) + ((Math.pow((y[j] - muY), 2)) / Math.pow(stdY, 2)) - ((2 * rho * (x[i] - muX) * (y[j] - muY)) / (stdX * stdY))));
    }
  }
  return { "x": x, "y": y, "z": z };

}
export default { CalculateGaussArray, SortArray, kurtosis, ellipseZ, CalculateMeanValue, CalculateVariance };
