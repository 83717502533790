<template>
  <div class="anomaly-plot-container">
    <div :ref="this.uuid" class="anomaly-plot"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist-min';

export default {
  name: "anomaly-plot",
  props: {
    title: String, //Stringa con it titolo del grafico
    tracesObject: Object, //Contiente gli oggetti 'standard' e 'anomaly', contententi l'insieme dei valori 'x','y' e i relativi event id.
    xTitle: String, //Titolo asse x
    yTitle: String, //Titolo asse y
  },
  data() {
    return {
      uuid: Math.random().toString(36).substr(2, 15),
    };
  },
  computed: {
    traces: function () {
      return [
        // Scatter eventi standard
        {
          x: this.tracesObject.standard ? this.tracesObject.standard.x : [],
          y: this.tracesObject.standard ? this.tracesObject.standard.y : [],
          idsx: this.tracesObject.standard ? this.tracesObject.standard.xOkIDS : [],
          idsy: this.tracesObject.standard ? this.tracesObject.standard.yOkIDS : [],
          mode: "markers",
          type: "scatter",
          name: "Standard",
          textposition: "top center",
          textfont: {
            family: "Raleway, sans-serif",
          },
          marker: { size: 12 },
        },
        // Scatter eventi anomali
        {
          x: this.tracesObject.anomaly ? this.tracesObject.anomaly.x : [],
          y: this.tracesObject.anomaly ? this.tracesObject.anomaly.y : [],
          idsx: this.tracesObject.anomaly ? this.tracesObject.anomaly.xAnomIDS : [],
          idsy: this.tracesObject.anomaly ? this.tracesObject.anomaly.yAnomIDS : [],
          mode: "markers",
          type: "scatter",
          name: "Anomalia",
          //text: ["B-a", "B-b", "B-c", "B-d", "B-e"],
          textfont: {
            family: "Times New Roman",
          },
          textposition: "bottom center",
          marker: { size: 12 },
        },
      ];
    },
    chart: function () {
      return {
        layout: {
          margin: {
            l: 120,
            r: 35,
            b: 90,
            t: 40,
            pad: 4,
          },
          xaxis: {
            title: {
              text: this.xTitle,
            },
            gridcolor: "rgba(150,153,162,0.6)",
            gridwidth: 1,
            linecolor: "rgba(150,153,162,0.6)",
            linewidth: 2,
          },
          yaxis: {
            title: {
              text: this.yTitle,
            },
            gridcolor: "rgba(150,153,162,0.4)",
            gridwidth: 1,
          },
          height: 800,
          legend: {
            y: 0.5,
            yref: "paper",
            font: {
              family: "Arial, sans-serif",
              size: 20,
              color: "grey",
            },
          },
          paper_bgcolor: "rgba(45,48,65,1)",
          plot_bgcolor: "rgba(45,48,65,1)",
          font: {
            family: "Poppins",
            size: 14,
            color: "rgb(255,255,255)",
          },
          hovermode: "closest",
        },
      };
    },
  },
  methods: {
    extractIDS(data) {
      // Estrae i due event id degli eventi relativi al punto selezionato sul grafico
      let index = data.points[0].pointIndex;
      let event1 = data.points[0].data.idsx[index];
      let event2 = data.points[0].data.idsy[index];
      // Emetto l 'evento con i due valori
      this.$emit("events-selected", event1, event2);
    },
  },
  mounted() {
    Plotly.newPlot(this.$refs[this.uuid], this.traces, this.chart.layout, {
      responsive: true,
    }).then((gd) => {
      gd.on("plotly_click", this.extractIDS);
    });
  },
  watch: {
    traces: {
      handler: function () {
        Plotly.react(this.$refs[this.uuid], this.traces, this.chart.layout);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
p {
  color: white;
}
</style>
