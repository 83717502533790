<i18n>
{
  "en": {
      "title": "Pk-Pk Displacement Probability Density Function",
      "subTitle": "The histogram highlights the intensity of the frequency of appearance of each peak to peak displacement value recorded by the selected DECK sensor, in the selected time interval. In this way it is possible to understand which is the average displacement of the structure and which is the anomalous one. The red curve highlights the trend of the Gaussian distribution equivalent to the same statistical indices of the histogram of the peaks."

  },
  "it": {
      "title": "Densità di Probabilità degli spostamenti Pk-Pk",
      "subTitle": "L’istogramma evidenzia l’intensità della frequenza di apparizione dei valori di spostamento Pk-Pk registrati dal sensore DECK selezionato, nell’intervallo temporale selezionato. In questo modo è possibile comprendere quali siano gli spostamenti medi della struttura e quali siano quelli meno frequenti. \nLa curva in rosso evidenzia l’andamento della distribuzione gaussiana equivalente agli stessi indici statistici dell’istogramma dei picchi."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "densProbBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 480px;
}
</style>
