<template>
  <div>
    <PageTemplate :hideTips="true">
      <template v-slot:nav>
        <DateRangeSelect @structure-selected="selectStructure" :selectedStructure="getStructureDetails" :availableStructures="getStructures" />
        <TopSensorSelect @type-selected="selectType" :sensors="getSensors" :selectedType="actualSensorType" v-if="getSensors.length > 0" />
      </template>
      <template v-slot:content>
        <div id="container" v-if="validSensors.length > 0">
          <DoubleSensorsSelect :sensorsType="actualSensorType" v-if="actualSensorType === 'deck' && validSensors.filter(sn => sn.type === actualSensorType).length > 1" />
          <DensProb :sensorsType="actualSensorType" v-if="actualSensorType === 'deck' && validSensors.filter(sn => sn.type === actualSensorType).length > 1" />
          <!-- Blocco Anomalie -->
          <AnomalyPlot v-if="actualSensorType && actualSensorType === 'deck' && validSensors.filter(sn => sn.type === actualSensorType).length > 1" />
          <!-- Blocco FDD -->
          <GroupSelect :sensorsType="actualSensorType" v-if="actualSensorType" />
          <FDD v-if="actualSensorType" :sensorsType="actualSensorType" graphuid="abcd1234" />
          <ModFreqs graphuid="sdf2342" v-if="getSelectedGroupID && actualSensorType" :sensorsType="actualSensorType" />
        </div>
        <div class="warning-container" v-else>
          <h2 v-if="loaded">No valid sensors.</h2>
        </div>
      </template>
    </PageTemplate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PageTemplate from "../PageTemplate.vue";
import TopSensorSelect from "../../components/ui/TopSensorSelect.vue";
import DateRangeSelect from "../../components/ui/DRSelect.vue";
import DoubleSensorsSelect from "../../components/modules/stats/DoubleSensorsSelect.vue";
import GroupSelect from "../../components/modules/stats/GroupSelect.vue";
import DensProb from "../../components/modules/stats/DensProb.vue";
import AnomalyPlot from "../../components/modules/stats/AnomalyPlot.vue";
import FDD from "../../components/modules/stats/FDD.vue";
import ModFreqs from "../../components/modules/stats/ModFreqs.vue";

export default {
  name: "app",
  data() {
    return {
      // True when api data is fetched
      loaded: false,
      showSidebar: false,
      showSettings: false,
      showMonitor: false,
      showAnalogNode: false,
      actualSensorType: null,
    };
  },
  components: {
    PageTemplate,
    TopSensorSelect,
    DateRangeSelect,
    GroupSelect,
    DensProb,
    AnomalyPlot,
    FDD,
    DoubleSensorsSelect,
    ModFreqs,
  },
  computed: {
    ...mapGetters("structure", ["getStructures", "getSensors", "getSelectedStructure", "getStructureDetails"]),
    ...mapGetters({
      getSelectedGroupID: "FDD/getSelectedGroupID",
      getUserType: "user/getUserType",
    }),
    userHasAdminRight() {
      return !this.getUserType || this.getUserType === "standard";
    },
    validSensors: function () {
      return this.getSensors.filter((sensor) => sensor.type === "deck" || sensor.type === "accelerometer");
    },
  },
  methods: {
    ...mapActions({
      fetchStructures: "structure/fetchStructures",
      fetchStructureData: "structure/fetchStructureData",
      selectStructure: "structure/selectStructure",
      setDensProb1Sens: "densProb1/setSelectedSensor",
      setDensProb2Sens: "densProb2/setSelectedSensor",
    }),
    ...mapActions("auth", ["logout"]),
    logoutSession: function () {
      this.logout();
    },
    selectType(type) {
      this.actualSensorType = type;
    },
  },
  /*   watch: {
    getSelectedStructure: {
      handler: async function (newValue) {
        if (newValue) {
          this.loaded = false;
          await this.fetchStructureData();
          this.loaded = true;
        }
      },
      deep: true,
    },
  }, */
  /* async mounted() {
    await this.fetchStructures();
    if (!this.getSelectedStructure) {
      await this.selectStructure(this.getStructures[0]);
    }
  }, */
};
</script>

<style scoped>
#container{
    margin-top: 80px;
}
</style>
