<template>
  <div class="over">
    <div class="heading">
      <h1 v-if="sensorsType === 'deck'">{{ $t("title.peakStats") }}</h1>
      <h1 v-if="sensorsType === 'accelerometer'">{{ $t("title.accStats") }}</h1>
    </div>
    <div class="content content-left" v-if="getSelectedSensor1">
      <div class="device-select noselect" @click="showDevice1 = !showDevice1">
        <p>{{ $t("message.deviceselect") }}:</p>
        <h2>{{ this.getSelectedSensor1.eui.substr(this.getSelectedSensor1.eui.length - 5) }}</h2>
        <i v-bind:class="[showDevice1 ? 'up' : 'down']"></i>
      </div>
      <ModalSensorSelect
        v-if="showDevice1"
        :sensorTypes="sensorsType"
        :values="filteredSensors"
        :selectedSensor="this.getSelectedSensor1"
        @selected="selectedDevice1"
        id="device1"
      />
    </div>
    <div class="content content-right" v-if="getSelectedSensor2">
      <div class="device-select noselect" @click="showDevice2 = !showDevice2">
        <p>{{ $t("message.deviceselect") }}:</p>
        <h2>{{ this.getSelectedSensor2.eui.substr(this.getSelectedSensor2.eui.length - 5) }}</h2>
        <i v-bind:class="[showDevice2 ? 'up' : 'down']"></i>
      </div>
      <ModalSensorSelect
        v-if="showDevice2"
        :sensorTypes="sensorsType"
        :values="filteredSensors"
        :selectedSensor="this.getSelectedSensor2"
        @selected="selectedDevice2"
        id="device2"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ModalSensorSelect from "../../ui/ModalSensorSelect.vue";
export default {
  name: "doubleSensorsSelect",
  components: {
    ModalSensorSelect,
  },
  data() {
    return {
      showDevice1: false,
      showDevice2: false,
    };
  },
  props: {
    sensorsType: String,
  },
  computed: {
    ...mapGetters({
      getSelectedSensor1: "densProb1/getSelectedSensor",
      getSelectedSensor2: "densProb2/getSelectedSensor",
      getSensors: "structure/getSensors"
    }),
    filteredSensors() {
      return this.getSensors.filter(i => (i.type === this.sensorsType));
    },
  },
  methods: {
    ...mapActions({
      setSelectedSensor1: "densProb1/setSelectedSensor",
      setSelectedSensor2: "densProb2/setSelectedSensor",
      fetchProb1: "densProb1/fetchDiffPeaks",
      fetchProb2: "densProb2/fetchDiffPeaks",
    }),
    selectedDevice1(sensor) {
      this.showDevice1 = false;
      this.setSelectedSensor1(sensor);
    },
    selectedDevice2(sensor) {
      this.showDevice2 = false;
      this.setSelectedSensor2(sensor);
    },
  },
  watch: {
    getSelectedSensor1: {
      handler: async function (newValue, oldValue) {
        if (newValue && oldValue) await this.fetchProb1();
      },
      deep: true,
    },
    getSelectedSensor2: {
      handler: async function (newValue, oldValue) {
        if (newValue && oldValue) await this.fetchProb2();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.setSelectedSensor1(this.filteredSensors[0]);
    await this.setSelectedSensor2(this.filteredSensors[1]);
  },
};
</script>
<style scoped>
p, h2{
  font-size: 0.9em;
}
p{
  margin:0;
}
/* Main Wrapper */
.over {
  min-width: 1000px;
  width: 92%;
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-template-rows: auto;
  margin-left: 4%;
  margin-right: 4%;
  height: fit-content;
  border: solid 2px white;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  padding: 0 5%;
  box-sizing: border-box;
}

/* Wrapper Evento */
.heading {
  text-align: center;
  display: flex;
  justify-content: center;
  color: white;
  width: 100%;
  height: fit-content;
  grid-row: 1;
  grid-column: 1/3;
  position: relative;
  border-bottom: solid 2px rgb(150, 153, 162);
}
h1 {
  font-size: 1em;
  padding: 8px 0;
}
.content {
  margin: 10px 0;
  width: 100%;
  height: fit-content;
  grid-row: 2;
  position: relative;
  display: flex;
  /* justify-content: center; */
}
.content-right {
  border-left: solid 2px rgb(150, 153, 162);
  padding-left: 5%;
}
.content-left {
  border-right: solid 2px rgb(150, 153, 162);
}

.device-select {
  margin-left: 25%;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.device-select h2 {
  margin-left: 5px;
}

/* Freccia */
i {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  position: relative;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 10px;
  bottom: 2px;
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.1, 0.96);
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#device1 {
  position: absolute;
  top: 50px;
  left: 280px;
}
#device2 {
  position: absolute;
  top: 50px;
  left: 280px;
}
</style>