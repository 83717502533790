<template>
  <div class="wrapper noselect">
    <div class="toggle_radio">
      <input type="radio" class="toggle_option" id="first_toggle" value="deck" :disabled="!this.searchForType(this.sensors, 'deck')" v-model="selected" />
      <input type="radio" class="toggle_option" id="second_toggle" value="accelerometer" :disabled="!this.searchForType(this.sensors, 'accelerometer')" v-model="selected" />
      <label for="first_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'deck') }">{{ $t("message.deck") }}</p>
      </label>
      <label for="second_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'accelerometer') }">{{ $t("message.accelerometer") }}</p>
      </label>
      <div class="toggle_option_slider"></div>
    </div>
  </div>
</template>
<script>
/* 
  Selezione Deck o Accelerometro
*/
export default {
  name: "TopSensorSelect",
  props: {
    sensors: Array,
    selectedType: String,
  },
  data() {
    return {
      selected: "",
      types: ["deck", "accelerometer"],
    };
  },
  methods: {
    searchForType: function (sensorsList, type) {
      let check = false;
      sensorsList.forEach((element) => {
        if (element.type === type) check = true;
      });
      return check;
    },
    getFirstSensorSelection: function () {
      for (let type of this.types) {
        if (this.searchForType(this.sensors, type)) {
          return type;
        }
      }
    },
    selectType: function (newType) {
      this.$emit("type-selected", newType);
    },
  },
  watch: {
    selected(newType) {
      this.selectType(newType);
    },
    /* Se cambio il sensore selezionato da fuori adeguo il tipo */
    selectedType(type) {
      if (type != this.selected) {
        this.selected = type;
      }
    },
    sensors(newSensors, oldSensors) {
      if (newSensors[0] && oldSensors[0] && newSensors[0]._id != oldSensors[0]._id) {
        this.selected = this.getFirstSensorSelection();
      }
    },
  },
  mounted() {
    this.selected = this.getFirstSensorSelection();
    this.$emit("type-selected", this.selected);
  },
};
</script>
<style scoped>
.wrapper {
  background-color: rgb(40, 42, 59);
  border-bottom: solid 1px rgb(80, 84, 105);
  width: 100%;
  display: grid;
  justify-content: center;
  padding: 10px 0;
}
p {
  font-size: 1em;
  margin: 0px 5px;
  padding: 0px;
  position: relative;
  z-index: 2;
}

.toggle_radio {
  position: relative;
  background-color: rgb(32, 34, 47);
  padding: 0 !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  height: 47px;
  display: grid;
  grid-template-rows: 100%;
  width: 500px;
  grid-template-columns: repeat(2, 50%);
  justify-content: center;
  box-sizing: border-box;
  justify-items: center;
}
.toggle_radio > * {
  float: left;
}
.toggle_radio input[type="radio"] {
  display: none;
}
.toggle_radio label {
  color: rgba(255, 255, 255, 0.9);
  font-family: inherit;
  font-weight: 600;
  font-size: 0.9em;
  display: block;
  width: max-content;
  box-sizing: border-box;
  height: 20px;
  margin: 9px 22px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.toggle_option_slider {
  width: 50%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 38px;
  align-self: center;
  justify-self: baseline;
  position: absolute;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#first_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  transform: translateX(1%);
}
#second_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  transform: translateX(97%);
}
.greyed {
  color: rgb(80, 84, 105);
}

@media all and (max-width: 1799px) and (min-width: 300px) {
  .toggle_radio {
    height: 37px;
  }
  .toggle_option_slider {
    height: 28px;
  }
}
</style>
